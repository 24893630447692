import api from '../api/api';
import imagePaths from '../assets/imagePaths';
import { ApiResponse } from '../models/ApiResponseModel';
import { UserData } from '../models/UserModel';
import paths from './constants';
import ReactDOM from "react-dom";

const CustomAlert = ({ message, onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          width: "400px",
          textAlign: "center",
        }}
      >
        {/* Icon */}
        <div
          style={{
            width: "50px",
            height: "50px",
            margin: "0 auto",
            backgroundColor: "#fce4e4",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <span
            style={{
              fontSize: "24px",
              color: "#d32f2f",
              fontWeight: "bold",
            }}
          >
          &#9432;
          </span>
        </div>

        {/* Title */}
        <div
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "#333333",
            marginBottom: "10px",
          }}
        >
          Diamond Diaries
        </div>

        {/* Message */}
        <div
          style={{
            fontSize: "16px",
            color: "#666666",
            marginBottom: "20px",
          }}
        >
          {message}
        </div>

        {/* Button */}
        <button
          onClick={onClose}
          style={{
            backgroundColor: "#552F83",
            color: "#ffffff",
            padding: "10px 20px",
            border: "none",
            borderRadius: "4px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};

const CustomEmailAlert = ({ message, onClose }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999,
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          width: "400px",
          textAlign: "center",
        }}
      >
        {/* Title */}
        <div
          style={{
            fontFamily:"Montserrat",
            fontSize: "16px",
            fontWeight: "600",
            color: "#323232",
            marginBottom: "10px",
          }}
        >
          Request Submitted Successfully!
        </div>
          <img  
          style={{
            width: "180px",
          }}
          src={imagePaths.emailSupport}></img>
        {/* Message */}
        <div
          style={{
            fontFamily:"Montserrat",
            fontSize: "14px",
            fontWeight: "500",
            color: "#323232",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
        {message} 
       </div>
        {/* Button */}
        <button
          onClick={onClose}
          style={{
            backgroundColor: "#552F83",
            color: "#ffffff",
            padding: "10px 20px",
            width:"200px",
            border: "none",
            fontWeight: "600",
            borderRadius: "8px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

const showAlert = (message) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  ReactDOM.render(<CustomAlert message={message} onClose={handleClose} />, container);
};
const showEmailSuccessAlert = (message) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  ReactDOM.render(<CustomEmailAlert message={message} onClose={handleClose} />, container);
};

export const logMessage = (message, type = 'log') => {
  switch (type) {
    case 'log':
      // console.log(message);
      break;
    case 'warn':
      console.warn(message);
      break;
    case 'error':
      console.error(message);
      break;
    case 'alert':
      showAlert(message);
      break;
    case 'email_alert':
      showEmailSuccessAlert(message);
      break;
    default:
      // console.log(message);
  }
};

export const websiteLink = "https://dreamerdesigns.com/";
export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const getLocalUserData = () => {
  const storedData = localStorage.getItem('user_data');
  if (storedData) {
    try {

      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.data) {
        const userData = new UserData(
          parsedData.data
        );

        return userData;
      } else {
        logMessage("invalid empty data")
        return null;
      }
    } catch (error) {

      logMessage("error parsing data")
      return null;
    }
  } else {
    logMessage("error parsing data", 'warn')
    return null;
  }
};

export const signOutUser = (navigate) => {
  localStorage.clear()
  setTimeout(() => {
    if (localStorage.length === 0) {
        navigate(paths.AUTH_SCREEN);
    } else {
        console.error("Failed to clear localStorage. Remaining keys:", Object.keys(localStorage));
    }
}, 500);
}

export const checkAuthorizationToken = (navigate) => {
  const storedData = localStorage.getItem('user_data');
  if (!storedData) {
    navigate(paths.AUTH_SCREEN);
  }
};
export const getLocalUserDataByKey = (key) => {
  const dataKey = localStorage.getItem(key);
  return dataKey;
}
export const formatHoursLogged = (seconds) => {
  const hours = (seconds / 3600).toFixed(1);
  return hours;
}
export const convertSecondsToHours = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const convertSecondsToHoursOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
 logMessage(hours)
  return hours;
};
export const convertSecondsToMinutesOnly = (totalSeconds) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  return minutes;
};
export const convertSecondsToSecondsOnly = (totalSeconds) => {
  const seconds = totalSeconds % 60;
  return seconds;
};
export const convertSecondsToHoursInnerCompleted = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;
  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${paddedHours} hours,  ${paddedMinutes} minutes and ${paddedSeconds} seconds`;
};
export const abbreviateNumberDouble = (number) => {
  if (number < 1000) {
    return number;
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return (number / 1000000).toFixed(1) + 'M';
  }
};
export const convertSecondsToHoursInner = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}  ${paddedMinutes}  ${paddedSeconds}`;
};

export const convertSecondsToHoursInnerHoursOnly = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');

  return `${paddedHours}`;
};
export const convertSecondsToHoursInnerMinutesOnly = (totalSeconds) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  // Pad single-digit values with leading zeros
  const paddedMinutes = minutes.toString().padStart(2, '0');

  return `${paddedMinutes}`;
};
export const convertSecondsToHoursInnerSecondsOnly = (totalSeconds) => {
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedSeconds}`;
};
export const convertSecondsToHoursLive = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  // Pad single-digit values with leading zeros
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};


export const convertText = (status) => {
  if(status === "not_started"){
    return "Not Started";
  }else if (status === "ongoing"){
    return "In-Progress";
  }else if (status === "completed"){
    return "Completed";
  }

}

export const verifyProjectAddition = async () => {
  const response = await api.verifyAddProject();
  const apiResponse = new ApiResponse(response.status, response.message);

  if (apiResponse.status === 1) {
    return "true";
  }else{
    return "false"; 
  }
};