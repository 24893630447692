import React, { useEffect, useState } from 'react';
import Header from '../../components/common/header';
import imagePaths from '../../assets/imagePaths';
import { GoStack } from "react-icons/go";
import api from '../../api/api.js';
import { ApiResponse } from '../../models/ApiResponseModel';
import { logMessage } from '../../utils/helpers.js';
import { useNavigate } from 'react-router-dom';
import paths from '../../utils/constants.js';
import { LeaderboardOuter } from '../../models/leaderboardModel.js';

const LeaderBoard = ({ startLoading, stopLoading }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("current");
    const [currentPage, setCurrentPage] = useState(1);
    const [leaderboardData, setLeaderboardData] = useState(new LeaderboardOuter({}));
    const [showPopup, setShowPopup] = useState(false);
    const repeatedParticipants = Array(10)
    .fill(leaderboardData.participants)
    .flat();
    const fetchProjects = async () => {
        try {            
            startLoading()
            const data = {
                "type": activeTab,
                "limit": 10,
                "page":currentPage
            };
            const response = await api.getLeaderboard(data);
            const apiResponse = new ApiResponse(
                response.status,
                response.message
            );
            if (apiResponse.status === 1) {
            //    const leaderboardData = new LeaderboardOuter(response.data);
               setLeaderboardData(response.data);
            } else {
                logMessage(apiResponse.message, 'alert');
            }
        } catch (error) {
            logMessage(error);
        } finally {
            stopLoading();
        }
    };
    // const handlePreviousPage = () => {
    //     setCurrentPage(currentPage - 1)
    // }
    // const handleNextPage = () => {
    //     setCurrentPage(currentPage + 1)
    // }

    const togglePopup = () => {
        logMessage("toggleclicked");
        setShowPopup(!showPopup);
        logMessage(showPopup);
      };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token || token.length <= 0) {
            navigate(paths.AUTH_SCREEN);
        }else{
        if (activeTab !== "") {
            fetchProjects();
        }
    }
    }, [activeTab, currentPage]);

    return (
        <div className='full_page_inner'>
            <Header />
            {showPopup && (
            <div className="popup-overlay">
              <div className="popup onlyInfoPopUp">
                <p style={{fontWeight:700}}>Leaderboard Information</p>
                <p style={{fontWeight:400, fontSize:'14px'}}><span style={{fontWeight:600, color:"#323232"}}>The Current Leaderboard</span> resets every two months, and prizes are awarded accordingly. Time is calculated based on the start and end dates of your projects.</p>
                <p style={{fontWeight:400, fontSize:'14px'}}><span style={{fontWeight:600, color:"#323232"}}>The All-Time Leaderboard</span>  is an accumulation of all projects with no specific timeframes.
                </p>
                <p style={{fontWeight:400, fontSize:'14px'}}><span style={{fontWeight:600, color:"#323232"}}>Points Calculation:</span>  Points are based on the number of projects completed multiplied by the size of the canvas. Proof is required through start and end date photos, which we manually verify.
                </p>
                <p style={{fontWeight:700}}>Example</p>
                <p style={{fontWeight:400,fontSize:'14px'}}>• 1 project (80cm x 60cm) = 4,800 points
                </p>
                <p style={{fontWeight:400,fontSize:'14px'}}>• 1 project (40cm x 50cm) = 2,000 points
                </p>
                <p style={{fontWeight:400, fontSize:'14px'}}><span style={{fontWeight:600, color:"#323232"}}>Total Score: </span>  4,800 + 2,000 = 6,800 points
                </p>
                <button className="delete-button doneInfoButton" onClick={togglePopup}>
                  Close
                </button>
              </div>
            </div>
          )}

            <div className='dashboard_container leaderboard_outer_container' style={{ backgroundImage: `url(${imagePaths.leaderboard})` }}>
           
                <div className='leaderboard_container'>
                    <div className='topButtonConatiner'>
                    {/* <div className='rightTop'>
                                <button
                                style={{visibility:"hidden"}}
                                onClick={togglePopup}
                                >i</button>
                            </div> */}
                        <div className='leftTop'>
                            
                        <button
                            className={`current ${activeTab === 'current' ? 'active' : ''}`}
                            onClick={() => setActiveTab('current')}
                        >
                            Current
                            </button>
                            <button
                            className={`all_time ${activeTab === 'all_time' ? 'active' : ''}`}
                            onClick={() => setActiveTab('all_time')}
                        >
                            All-Time
                        </button>
                        </div>
                        {/* <div className='rightTop'> */}
                                {/* <button */}
                                {/* onClick={togglePopup} */}
                                {/* > */}
                                                            
                                {/* </button> */}
                            {/* </div> */}
                    </div>
                    <p onClick={togglePopup} className='howInfo'>How The Leaderboard Works?   <img 
                                    onClick={togglePopup} 
                                    style={{ cursor: "pointer", width:'18px', marginLeft:"8px" }} 
                                    src={imagePaths.info} 
                                    alt="info" 
                                /> </p>
                                <p className='leaderboardDescription'>{leaderboardData.description}</p>
                    {leaderboardData.participants.length > 0 ? (
                    <div className='innerWinnerContainer' style={{display:"contents"}}>
                    <div className='topWinnerContainer'>
                    <div className='secondWinnerCard commonWinnerCard'>
                        <div className='imageSections'>
                            <img 
                                className="profileImage"  
                                src={
                                    leaderboardData.second?.profile_picture 
                                    ? leaderboardData.second?.profile_picture 
                                    : imagePaths.demo_profile
                                }
                                alt="Profile"
                            />
                            <div className="badgeWrapper">
                                <img className="badge" src={imagePaths.silver} alt="Badge" />
                                <span className="badgeText">{leaderboardData.second?.rank}</span>
                            </div>
                        </div>
                        <p className='leaderboardName'>
                            {leaderboardData.second?.name || 'Guest'}
                        </p>
                        <div className='middleSection'>
                            <img src={imagePaths.reward} alt="Reward" />
                            <p>
                                <span className='numberPts'>
                                    {leaderboardData.second?.total_points || 0}
                                </span> pts
                            </p>
                        </div>
                        <div className='bottomSection'>
                            <GoStack />
                            <p>{leaderboardData.second?.numberOfProjects || 0}</p>
                        </div>
                    </div>
                         {/* {leaderboardData.participants[0] && ( */}
                        <div className='firstWinnerCard'  style={{ backgroundImage: `url(${imagePaths.firstwinner})` }}>
                            <div className='imageSections'>
                                <img className="profileImage"  src={
                                        leaderboardData.first?.profile_picture 
                                        ? leaderboardData.first?.profile_picture 
                                        : imagePaths.demo_profile
                                    }>
                                </img>
                                <div className="badgeWrapper">
                                <img className="badge" src={imagePaths.gold}  alt="Badge">
                                </img>
                                <span className="badgeText">{leaderboardData.first?.rank}</span>
                                </div>
                            </div>
                            <p className='leaderboardName'>{leaderboardData.first?.name}</p>
                            <div className='middleSection'>
                            <img src={imagePaths.reward}>
                            </img>
                            <p><span className='numberPts'>{leaderboardData.first?.total_points}</span> pts</p>
                            </div>
                            <div className='bottomSection'>
                            <GoStack />
                            <p>{leaderboardData.first?.numberOfProjects}</p>
                            </div>
                        </div>
                
                        <div className='thirdWinnerCard commonWinnerCard'>
                            <div className='imageSections'>
                            <img className="profileImage"  src={
                                        leaderboardData.third?.profile_picture 
                                        ? leaderboardData.third?.profile_picture 
                                        : imagePaths.demo_profile
                                    }>
                                </img>
                                <div className="badgeWrapper">
                                <img className="badge" src={imagePaths.bronze}  alt="Badge">
                                </img>
                                <span className="badgeText thirdBadgeText">{leaderboardData.third?.rank}</span>
                                </div>
                            </div>
                            <p className='leaderboardName'>{leaderboardData.third?.name || 'Guest'}</p>
                            <div className='middleSection'>
                            <img src={imagePaths.reward}>
                            </img>
                            <p><span className='numberPts'>{leaderboardData.third?.total_points || '0'}</span> pts</p>
                            </div>
                            <div className='bottomSection'>
                                <GoStack />
                                <p>{leaderboardData.third?.numberOfProjects || '0'}</p>
                            </div>
                        </div>
                    </div>
                    {leaderboardData.participants.length > 0 && (
                    <div className='bottomWinnerContainer'>
                {leaderboardData.participants.slice(0).map((participant, index) => (
                        <div className="tilesContainer" key={index}>
                            <div className="leftTileSide">
                                <span
                                    className="rankBadge"
                                    style={{ backgroundImage: `url(${imagePaths.tilesreward})` }}
                                >
                                    {participant.rank}
                                </span>
                                <img
                                    className="profileImage"
                                    src={
                                        participant.profile_picture
                                            ? participant.profile_picture
                                            : imagePaths.demo_profile
                                    }
                                    alt="Profile"
                                />
                                <span className="userName">
                                    {participant.name || "Unknown User"}
                                </span>
                            </div>
                            <div className="middleTileSide">
                                <div className="score">
                                    <GoStack />
                                    <span className="stackValue">
                                        {participant.numberOfProjects || "0"}
                                    </span>
                                </div>
                            </div>
                            <div className="rightTileSide">
                                <div className="points">
                                    <img
                                        className="icon"
                                        src={imagePaths.reward}
                                        alt="Points Icon"
                                    />
                                    <span className="pointsValue">
                                        {participant.total_points || "0 pts"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                    )}
                    <div class="leaderboard-pagination-container">
                       
                    <button 
                        className="pagination-button previous" 
                        onClick={() => setCurrentPage(currentPage - 1)} 
                        disabled={currentPage === 1}
                    >
                    Previous
                    </button>  
                    <span className='currentPageLeaderboard'>{currentPage}                 
                    </span>
                        <button 
                            className="pagination-button next" 
                            onClick={() => setCurrentPage(currentPage + 1)} 
                            disabled={!leaderboardData.hasNextPage}
                            >
                            Next
                        </button>    
                                       
                    </div>
                    
                    </div>
                     ) : (
                        <p className="noUsersText">Currently no data available</p>
                      )}
                </div>
            </div>
        </div>
    );
};

export default LeaderBoard;