import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { FaRegCirclePause, FaCirclePlay } from "react-icons/fa6";
import { RiEditFill } from "react-icons/ri";
import { AiFillCloseSquare } from "react-icons/ai";
import { RxReload } from "react-icons/rx";
import { MdFileUpload } from "react-icons/md";
import { LuRadar } from "react-icons/lu";
import { CiMenuKebab } from "react-icons/ci";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import { ReactComponent as GraphIcon } from "../../images/graphicon.svg";
import { ReactComponent as Diamonds } from "../../images/diamonds.svg";
import { ReactComponent as BarIcon } from "../../images/bar.svg";
import {
  convertSecondsToHoursInnerCompleted,
  convertSecondsToHoursInnerHoursOnly,
  convertSecondsToHoursInnerMinutesOnly,
  convertSecondsToHoursInnerSecondsOnly,
  convertSecondsToHoursOnly,
  convertSecondsToMinutesOnly,
  convertSecondsToSecondsOnly,
  convertText,
  formatNumber,
  logMessage,
} from "../../utils/helpers.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { Project } from "../../models/projectModel.js";
import paths from "../../utils/constants.js";
import { IoIosWarning } from "react-icons/io";
import { FaRegFileImage } from "react-icons/fa";
const ProjectDetail = ({ startLoading, stopLoading }) => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [liveTimer, setLiveTimer] = useState(0);
  const [showEditPopup, setEditShowPopup] = useState(false);
  const [isLocallyStartImage, setLocallyStartImage] = useState(false);
  const [isLocallyEndImage, setLocallyEndImage] = useState(false);
  const [canvasStartImage, setCanvasStartImage] = useState("");
  const [canvasCompleteImage, setCanvasCompleteImage] = useState("");
  const [startImageServer, setStartImageServer] = useState(false);
  const [endImageServer, setEndImageServer] = useState(false);
  const [startImageServerUrl, setStartServerImageUrl] = useState(false);
  const [endImageServerUrl, setEndServerImageUrl] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("project_id");
  const [projectDetail, setProjectDetail] = useState(new Project({}));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteProjectConfirmation, setDeleteProjectConfirmation] =
    useState(false);
  const [selectedHours, setSelectedHours] = useState(0);
  const [timerStatus, setTimerStatus] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState(0);
  const [selectedSeconds, setSelectedSeconds] = useState(0);

  const handleMinutesChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (value > 59) {
      logMessage("invalid seconds value", "alert");
    } else {
      setSelectedMinutes(value);
    }
  };

  const handleAnyEditClick = (e) => {
    logMessage("clickedinside");
    e.preventDefault();
  };

  const handleStartCross = (e) => {
    if(startImageServer){
      setCanvasStartImage(startImageServerUrl)
    }else{
      setCanvasStartImage('')
    }
    setLocallyStartImage(false)
  };

  const handleEndCross = (e) => {
    if(endImageServer){
      setCanvasCompleteImage(endImageServerUrl)
    }else{
      setCanvasCompleteImage('')
    }
    setLocallyEndImage(false)
  };
  
  const handleSecondsChange = (e) => {
    let value = parseInt(e.target.value) || 0;
    if (value > 59) {
      logMessage("invalid seconds value", "alert");
    } else {
      setSelectedSeconds(value);
    }
  };

  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };
  const editTogglePopup = () => {
    logMessage("toggleclicked");
    setEditShowPopup(!showEditPopup);
  };
  const handleUpdateStatus = async () => {
    // if (status == "ongoing") {
    logMessage("handle update status clciked");
    togglePopup();
    // }
  };
  const updateProjectStatus = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.updateProjectStatus(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        togglePopup();
        fetchProjectDetail();
        stopLoading();
        // setShowUI(true);
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const handleTimer = async (type, project_id) => {
    if (type == "stop") {
      stop_timer(project_id);
    } else {
      start_timer(project_id);
    }
    logMessage("handle timer clicked");
  };

  const start_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.startTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjectDetail();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const [startFormData, setStartFormData] = useState({
    image:""
  });

  const handleStartFileChange = (event) => {
   
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader(); // Create a FileReader instance
      reader.onload = (e) => {
        // When file is successfully read
        setStartFormData(() => ({
          image: file, // Set the image in start form data
        }));
        setCanvasStartImage(e.target.result);
        setLocallyStartImage(true) // Update the canvas image state
        logMessage("New canvas start image:"+ e.target.result); // Log the updated value
      };
      reader.readAsDataURL(file); // Read the file as a base64 data URL
    }
  };

  const uploadStartImage = async () => {
    try {
      startLoading();
      const response = await api.addCanvasImages(projectId, startFormData, "start");
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        
        setLocallyStartImage(false)
        logMessage(apiResponse.message, "alert");
        setStartImageServer(true);
        setStartServerImageUrl(response.data.canvas_start_image);
        stopLoading();
      }
      else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const [completeFormData, setCompleteFormData] = useState({
    image:""
  });

  const handleEndFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader(); // Create a FileReader instance
      reader.onload = (e) => {
        // When file is successfully read
        setCompleteFormData(() => ({
          image: file, // Set the image in start form data
        }));
        setCanvasCompleteImage(e.target.result);
        setLocallyEndImage(true) // Update the canvas image state
        logMessage("New canvas end image:"+ e.target.result); // Log the updated value
      };
      reader.readAsDataURL(file); // Read the file as a base64 data URL
    }
  };
  
  const uploadEndImage = async () => {
    try {
      startLoading();
      const response = await api.addCanvasImages(projectId, completeFormData, "end");
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        // console.log(response);
        setLocallyEndImage(false)
        setEndImageServer(true)
        setEndServerImageUrl(response.data.canvas_complete_image);
        logMessage(apiResponse.message, "alert");
        stopLoading();
      }
      else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const stop_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.stopTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        fetchProjectDetail();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const openMenu = () => {
    logMessage("clicked");
    setIsMenuOpen(!isMenuOpen);
  };
  const fetchProjectDetail = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.getProjectDetail(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        logMessage(response.data);
        setProjectDetail(response.data);
        setCanvasCompleteImage(response.data.canvas_complete_image);
        setCanvasStartImage(response.data.canvas_start_image);
        setStartImageServer(response.data.canvas_start_image == "" ? false : true)
        setEndImageServer(response.data.canvas_complete_image == "" ? false : true)
        setStartServerImageUrl(response.data.canvas_start_image);
        setEndServerImageUrl(response.data.canvas_complete_image);
        const hoursOnly = convertSecondsToHoursOnly(
          response.data.totalDuration
        );
        const minutesonly = convertSecondsToMinutesOnly(
          response.data.totalDuration
        );
        const secondsOnly = convertSecondsToSecondsOnly(
          response.data.totalDuration
        );
        setSelectedHours(hoursOnly);
        setSelectedMinutes(minutesonly);
        setSelectedSeconds(secondsOnly);
        setTimerStatus(response.data.timer_status);
        logMessage(response.data.timer_status);
        if (response.data.timer_status === "started") {
          logMessage("livetimer");
          setLiveTimer(response.data.totalDuration);
        }
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    logMessage(liveTimer);
    // if (liveTimer !== 0) {
      const intervalId = setInterval(() => {
        setLiveTimer(liveTimer + 1);
      }, 1000);
      return () => clearInterval(intervalId);
    // }
  }, [liveTimer]);

  const handleClickOutside = (event) => {
    logMessage("outsideclicked");
    if (
      event.target.classList.contains("menuInner") ||
      event.target.classList.contains("dropbtn") ||
      event.target.classList.contains("editpopup")
    ) {
    } else {
      logMessage("inside menu outside");
      setIsMenuOpen(false);
      // setEditShowPopup(false)
    }
  };
  const callProjectConfirmation = () => {
    setDeleteProjectConfirmation(!deleteProjectConfirmation);
  };
  const goToEdit = async () => {
    navigate(paths.EDIT_PROJECT + "?project_id=" + projectId);
  };
  const deleteProject = async () => {
    try {
      startLoading();
      const data = {
        project_id: projectId,
      };
      const response = await api.deleteProjectData(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        navigate(paths.PROJECT_SCREEN);
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  const editTimer = async () => {
    try {
      const stopTimerData = {
        project_id: projectId,
      };
      if (timerStatus == "started") {
        await api.stopTimer(stopTimerData);
      }
      startLoading();
      const duration =
        selectedHours * 3600 + selectedMinutes * 60 + selectedSeconds;
      logMessage(duration);
      const data = {
        project_id: projectId,
        timer: duration,
      };
      const response = await api.editTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        window.location.reload();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };
  const resetTimer = async () => {
    try {
      startLoading();
      const stopTimerData = {
        project_id: projectId,
      };
      if (timerStatus == "started") {
        await api.stopTimer(stopTimerData);
      }

      const duration = 0;
      logMessage(duration);
      const data = {
        project_id: projectId,
        timer: duration,
      };
      const response = await api.editTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        window.location.reload();
        stopLoading();
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    logMessage(projectId);
    logMessage(deleteProjectConfirmation);
    // showDeleteProjectConfirmation(false)
    fetchProjectDetail();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <div className="full_page_inner">
      <Header />
      <div className="detail_outer_container">
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup newsortpop">
              <div className="popup_header">
                <h2 className="completedPopText">Is the project complete?</h2>
              </div>
              <div className="popbodycontent">
                <button className="delete-button" onClick={updateProjectStatus}>
                Yes, It’s Complete
                </button>
                <br></br>
                <button className="cancel-button cancelcomplete" onClick={togglePopup}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {deleteProjectConfirmation && (
          <div className="popup-overlay">
            <div className="popup">
              <h2>Delete Project</h2>
              <p>Are you sure you want to delete project?</p>
              <div className="warning">
              <div className="warningtextbox">
                  <p className="warningtext">
                    <IoIosWarning /> Warning
                  </p>
                  </div>
                <p>
                  By deleting the project you will lose your project data and
                  its media.
                </p>
              </div>
              <button className="delete-button" onClick={deleteProject}>
                Delete Project
              </button>
              <button
                className="cancel-button cancelcomplete"
                onClick={callProjectConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {showEditPopup && (
          <div className="popup-overlay">
            <div className="popup newsortpop editpopup">
              <div className="popup_header">
                <h2 className="editTitleTimer">Update Logged Timer</h2>
                <span
                  onClick={editTogglePopup}
                  className="crossicon"
                  style={{ color: "#000", fontSize: "26px" }}
                >
                  <AiFillCloseSquare />
                </span>
              </div>

              <div className="popbodycontent">
                <div className="timereditor">
                  <div className="hoursbox">
                    <input
                      type="number"
                      value={selectedHours}
                      onChange={(e) => setSelectedHours(e.target.value)}
                    ></input>
                    <span className="mintext">Hours</span>
                  </div>
                  <div className="minutesbox">
                    <input
                      type="number"
                      value={selectedMinutes}
                      onChange={handleMinutesChange}
                    ></input>
                    <span className="mintext">Minutes</span>
                  </div>
                  <div className="secondsbox">
                    <input
                      type="number"
                      value={selectedSeconds}
                      onChange={handleSecondsChange}
                    ></input>
                    <span className="mintext">Second</span>
                  </div>
                </div>
                <button className="delete-button updateloggedtime" onClick={editTimer}>
                  Submit
                </button>
                <br></br>
                <button className="reset-button resetloggedtime" onClick={resetTimer}>
                  <RxReload /> Reset
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="detail_inner_container projectDetail">
          {timerStatus != "" && (
            <div className="detail_left_container left_inner_detail">
              <div className="imageContainer">
                <img alt="project_image" src={projectDetail.image[0]}></img>
              </div>
          {projectDetail.project_status === "not_started" ? (
              <div className="logTimerContainer notstartedcontainer">
              <p className="not_started_text">Project hasn’t started, click the timer to start</p>
              <div className="notstartedactionblock">
                      <div className="play_button">
                        {projectDetail.timer_status === "started" ? (
                          <FaRegCirclePause
                            className="timer_duration"
                            onClick={(event) => {
                              handleTimer("stop", projectId);
                            }}
                          />
                        ) : (
                          <img src={imagePaths.play}
                            className="timer_duration notstartedplay"
                            onClick={(event) => {
                              handleTimer("start", projectId);
                            }}
                          />
                        )}
                      </div>
                      
                    </div>
            </div>
            ) :projectDetail.project_status == "ongoing" ? (
                <>
                  <div className="logTimerContainer">
                    <div className="time_inner">
                      <p style={{ display: "flex", fontSize: "18px",alignItems: "end" }}>
                        Logged time:{" "}
                        <RiEditFill
                        className="iconedit"
                          onClick={editTogglePopup}
                          style={{ fontSize: "18px" }}
                        />
                      </p>
                      <span style={{ fontSize: "18px" }}>
                        {projectDetail.timer_status === "started" ? (
                          <>  <div className="full_timer_box">
                          <div className="hoursBox">
                            <p className="timerNum">
                            {convertSecondsToHoursInnerHoursOnly(
                            liveTimer
                          )} 
                            </p>
                            <p className="timertext">Hr</p>
                          </div>
                          <div className="timerDots">:</div>
                          <div className="minutesBox">
                            <p className="timerNum">
                            {convertSecondsToHoursInnerMinutesOnly(
                            liveTimer
                          )} 
                            </p>
                            <p className="timertext">Min</p>
                          </div>
                          <div className="timerDots">:</div>
                          <div className="secondsBox">
                            <p className="timerNum">
                            {convertSecondsToHoursInnerSecondsOnly(
                            liveTimer
                          )} 
                            </p>
                            <p className="timertext">Sec</p>
                          </div>
                          
                        </div></>
                        ) : (
                          <>
                          <div className="full_timer_box">
                            <div className="hoursBox">
                              <p className="timerNum">
                              {convertSecondsToHoursInnerHoursOnly(
                              projectDetail.totalDuration
                            )} 
                              </p>
                              <p className="timertext">Hr</p>
                            </div>
                            <div className="timerDots">:</div>
                            <div className="minutesBox">
                              <p className="timerNum">
                              {convertSecondsToHoursInnerMinutesOnly(
                              projectDetail.totalDuration
                            )} 
                              </p>
                              <p className="timertext">Min</p>
                            </div>
                            <div className="timerDots">:</div>
                            <div className="secondsBox">
                              <p className="timerNum">
                              {convertSecondsToHoursInnerSecondsOnly(
                              projectDetail.totalDuration
                            )} 
                              </p>
                              <p className="timertext">Sec</p>
                            </div>
                            
                          </div>
                           
                          </>
                        )}
                      </span>
                    </div>
                    <div className="actionblock">
                      <div className="play_button">
                        {projectDetail.timer_status === "started" ? (
                          <FaRegCirclePause
                            className="timer_duration"
                            onClick={(event) => {
                              handleTimer("stop", projectId);
                            }}
                          />
                        ) : (
                          <FaCirclePlay
                            className="timer_duration"
                            onClick={(event) => {
                              handleTimer("start", projectId);
                            }}
                          />
                        )}
                      </div>
                      {projectDetail.project_status === "ongoing" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleUpdateStatus}
                          className="projectaction"
                        >
                          Mark As Complete
                        </span>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="logTimerContainer completedcontainer">
                  <h2>Project complete!</h2>
                  <p>
                  Total time taken was:{" "}<span>
                    {convertSecondsToHoursInnerCompleted(
                      projectDetail.totalDuration
                    )}
                    </span>
                  </p>
                </div>
              )}
            </div>
          )}
          {timerStatus != "" && (
            <div className="detail_right_container sample_detail_right">
              <h2 className="main_design_name">
                {" "}
                {projectDetail.design}
                <CiMenuKebab onClick={openMenu} />
              </h2>
              {isMenuOpen && (
                <div
                  className="dropdown-content"
                  style={{ top: "155px", right: "94px" }}
                >
                  <p className="menuInner" onClick={goToEdit}>
                    Edit project
                  </p>
                  <p className="menuInner" onClick={callProjectConfirmation}>
                    Delete project
                  </p>
                </div>
              )}
              <h2 className="artist_name">© {projectDetail.artist}</h2>
              <div className="price">
                {projectDetail.price} {projectDetail.currency_code}
              </div>

              <div className="details_box">
                {timerStatus != "" && (
                  <div
                    className="status_box"
                    style={{
                      backgroundImage: `url(${
                        projectDetail.project_status === "completed"
                          ? imagePaths.background_completed
                          : projectDetail.project_status === "ongoing"
                          ? imagePaths.background_ongoing
                          : projectDetail.project_status === "not_started"
                          ? imagePaths.background_not_started
                          : ""
                      })`,
                    }}
                  >
                    <LuRadar />
                    <span className={`status ${projectDetail.project_status}`}>
                      {convertText(projectDetail.project_status)}
                    </span>
                  </div>
                )}
                <h3>Dreamer Designs</h3>
                <p>
                  {" "}
                  <GraphIcon className="svgicon" /> <strong className="boldTitles">
                    Size:
                  </strong>{" "}
                  {projectDetail.canvas_width} x {projectDetail.canvas_height}cm
                </p>
                <br></br>
                <p>
                <Diamonds className="diamondicon" />
                  <strong className="boldTitles">Diamonds:</strong>{" "}
                  {projectDetail.diamond_type.charAt(0).toUpperCase() +
                    projectDetail.diamond_type.slice(1).toLowerCase()}{" "}
                  with {projectDetail.no_of_color} colours
                  {projectDetail.abs > 0 &&
                    ` including ${projectDetail.abs} AB’s`}
                  {projectDetail.glow > 0 && `, ${projectDetail.glow} glow`}
                  {projectDetail.crystal > 0 &&
                    ` and ${projectDetail.crystal} crystal drills`}
                </p>
                <p>
                <BarIcon className="baricon" />
                  {projectDetail.total_amount_diamonds < 2500 ? (
                    <strong className="boldTitles">Beginner level </strong>
                  ) : (
                    <strong className="boldTitles">Pro level </strong>
                  )}{" "}
                    with {formatNumber(projectDetail.total_amount_diamonds)}{" "}
                  diamonds
                </p>
              </div>
              {projectDetail.tags.length > 0 && (
                <div className="tags">
                  <h3>Tags</h3>
                  {projectDetail.tags.map((tag, index) => (
                    <span key={index}>{tag}</span>
                  ))}
                </div>
              )}
              {projectDetail.extraNotes && (
                <p className="detail_line">{projectDetail.extraNotes}</p>
              )}
               {projectDetail.submit_project && (
               <p className="detail_line" style={{color:"#E84343", border:"0px"}}>You have opted to submit this project to the Leaderboard.</p>
              )}
               {projectDetail.submit_project && (
               <div style={{
                border: '0.5px dashed #323232',
                borderRadius: '8px',
                padding: '16px',
                display:'flex',
                alignItems:'center',
                // textAlign: 'center',
                // position: 'relative',
                width: '350px',
              }}>
                  <input
                  type="file"
                  style={{ display: 'none' }}
                  id="imageUploadInputStart"
                  accept="image/png, image/jpeg"
                  onChange={handleStartFileChange}
                />
                <div 
                 onClick={() => document.getElementById('imageUploadInputStart').click()}
                 style={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  background: '#EAE8FB',
                  justifyContent: 'center', 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                   <img
                    src={canvasStartImage == "" ? imagePaths.thumbnail : canvasStartImage}
                    alt="Uploaded"
                    style={{
                      width: canvasStartImage == "" ? '25px' : '100px',
                      height: canvasStartImage == "" ? '25px' : '100px',
                      objectFit: 'cover',
                    }}
                  />
                  { isLocallyStartImage &&(
                   <button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '2px',
                    background: 'red',
                    color: 'white',
                    display:canvasStartImage == "" ? 'none' : 'block',
                    border: 'none',
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                    fontSize: '18px',
                    lineHeight: '12px',
                    padding:'1px 2px 2px 2px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleStartCross()}
                >
                    ×
                  </button> 
                )}
                </div>
                <div  style={{
                  display:'block',
                  marginLeft:'10px'
                }}>
                <p className="canvas_titles" style={{ margin: '4px 0 8px'}}>Canvas Start</p>
                <p className="canvas_subtitles" style={{ margin: '0', fontSize: '12px', color: '#666' }}>Up to 5MB and in png or jpg only</p>
                { isLocallyStartImage && (
                <button
                className="uploadCanvasImageButton"
                onClick={uploadStartImage}
                  style={{
                    marginTop: '16px',
                    backgroundColor: '#fff',
                    color: '#00934C',
                    border: '2px solid #00934C',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }} >
                  <FaRegFileImage className="saveImageIcon"/>
                 <p className="saveImage"> Save Image</p>
                </button>
                )}
                  { !isLocallyStartImage && (
                  <button
                className="uploadCanvasImageButton"
                onClick={() => document.getElementById('imageUploadInputStart').click()}
                  style={{
                    marginTop: '16px',
                    backgroundColor: '#fff',
                    color: '#552F83',
                    border: startImageServer === true ? '2px solid #39A5DB' : '2px solid #552F83',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }} >
                   {startImageServer === false ? (
                      <MdFileUpload className="uploadIconCanvas" />
                    ) : (
                      <FaRegFileImage className="changeImageIconDetail" />
                    )}
                  <p className={startImageServer === true ? "changeImageDetail" :""}> 
               {startImageServer === false ? "Choose Image" : "Change Image"}
                </p>                
               </button>
          )}
              </div>
              </div>
  )}
    {projectDetail.submit_project && (
              <div style={{
                border: '0.5px dashed #323232',
                borderRadius: '8px',
                padding: '16px',
                display:'flex',
                alignItems:'center',
                marginTop:'10px',
                // textAlign: 'center',
                // position: 'relative',
                width: '350px',
              }}>
                 <input
                  type="file"
                  style={{ display: 'none' }}
                  id="imageUploadInputEnd"
                  accept="image/png, image/jpeg"
                  onChange={handleEndFileChange}
                />
                <div 
                  onClick={() => document.getElementById('imageUploadInputEnd').click()}
                  style={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  background: '#EAE8FB',
                  justifyContent: 'center', 
                  display: 'flex',
                  alignItems: 'center',
                }}>
                   <img
                    src={canvasCompleteImage == "" ? imagePaths.thumbnail : canvasCompleteImage}
                    alt="Uploaded"
                    style={{
                      width: canvasCompleteImage == "" ? '25px' : '100px', // Conditional image size
                      height: canvasCompleteImage == "" ? '25px' : '100px',
                      objectFit: 'cover',
                    }}
                  />
                    { isLocallyEndImage && (
                   <button
                  style={{
                    position: 'absolute',
                    top: '2px',
                    right: '2px',
                    background: 'red',
                    color: 'white',
                    display:canvasCompleteImage == "" ? 'none' : 'block',
                    border: 'none',
                    borderRadius: '50%',
                    width: '15px',
                    height: '15px',
                    fontSize: '18px',
                    lineHeight: '12px',
                    padding:'1px 2px 2px 2px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleEndCross('')}
                >
                    ×
                  </button>
                  )}
                </div>
                <div  style={{
                  display:'block',
                  marginLeft:'10px'
                }}>
                <p className="canvas_titles" style={{ margin: '4px 0 8px'}}>Canvas Finish</p>
                <p className="canvas_subtitles" style={{ margin: '0', fontSize: '12px', color: '#666' }}>Up to 5MB and in png or jpg only</p>
                { isLocallyEndImage && (
                <button
                 onClick={uploadEndImage}
                className="uploadCanvasImageButton"
                  style={{
                    marginTop: '16px',
                    backgroundColor: '#fff',
                    color: '#00934C',
                    border: '2px solid #00934C',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }} >
                  <FaRegFileImage className="saveImageIcon" />
                  <p className="saveImage"> Save Image</p>
                </button>
                )}
                  { !isLocallyEndImage && (
                <button
                onClick={() => document.getElementById('imageUploadInputEnd').click()}
                className="uploadCanvasImageButton"
                  style={{
                    marginTop: '16px',
                    backgroundColor: '#fff',
                    color: '#552F83',
                    border: endImageServer === true ? '2px solid #39A5DB' : '2px solid #552F83',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }} >
                   {endImageServer === false ? (
                      <MdFileUpload className="uploadIconCanvas" />
                    ) : (
                      <FaRegFileImage className="changeImageIconDetail" />
                    )}
                 <p className={endImageServer === true ? "changeImageDetail" :""}> {endImageServer === false ? "Choose Image" : "Change Image"}
                 </p>
                </button>
                 )}
              </div>
              </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
