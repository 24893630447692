import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import {
  convertSecondsToHoursLive,
  convertText,
  logMessage,
} from "../../utils/helpers.js";
import { BiSort } from "react-icons/bi";
import { LuRadar } from "react-icons/lu";
import { FaRegCirclePlay, FaRegCirclePause } from "react-icons/fa6";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import Header from "../../components/common/header.js";
import imagePaths from "../../assets/imagePaths.js";
import paths from "../../utils/constants.js";
import api from "../../api/api.js";
import { ApiResponse } from "../../models/ApiResponseModel.js";
import { DataModel } from "../../models/projectModel.js";
import { convertSecondsToHours } from "../../utils/helpers.js";
import {
  AiOutlineClose,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";

const ProjectScreen = ({ startLoading, stopLoading }) => {
  const navigate = useNavigate();
  const [type, setType] = useState("completed");
  const [liveTimer, setLiveTimer] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showPlansPopup, setShowPlansPopup] = useState(false);
  const [showSortPopup, setSortShowPopup] = useState(false);
  const [updateProjectId, setUpdatedId] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(0);
  const [search, setSearchKey] = useState("");
  const [projectData, setProjectData] = useState(new DataModel({}));
  const [showUI, setShowUI] = useState(false);
  const [noUItext, setNoUiText] = useState("");
  const [sortTypeKey, setSortTypeKey] = useState("");
  const [sortPattern, setSortPattern] = useState("");
  const [sortType, setSortType] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    type: "",
    sort: "",
    diamondType: "",
  });
  const [diamondTypeSort, setDiamondTypeSort] = useState("");
  const handleSortButtons = (pattern, type) => {
    setSortPattern(pattern);
    setSortType(type);
    if (type == "updated_time") {
      setSortTypeKey("Date Modified");
    } else {
      setSortTypeKey(type);
    }
  };
  const handleSort = async () => {
    toggleSortPopup();
    logMessage("sortPattern" + sortPattern);
    const sortData = {
      type: sortPattern,
      diamondType: diamondTypeSort,
      sort: sortType,
    };
    setSelectedFilters(sortData);
    await fetchProjectsNew(sortData);
    // await searchProducts();
  };
  const clearFilter = async (type) => {
    if (type == "diamondType") {
      const updatedFilters = {
        ...selectedFilters,
        diamondType: "",
      };

      // await setSelectedFilters((prevFilters) => ({
      //   ...prevFilters,
      //   diamondType: "",
      // }));
      setSelectedFilters(updatedFilters);

      setDiamondTypeSort("");
      await fetchProjectsNew(updatedFilters);
    } else {
      const updatedFilters = {
        ...selectedFilters,
        sort: "",
      };

      // await setSelectedFilters((prevFilters) => ({
      //   ...prevFilters,
      //   sort: "",
      // }));
      setSelectedFilters(updatedFilters);
      setSortPattern("");
      setSortType("");
      setSortTypeKey("");
      await fetchProjectsNew(updatedFilters);
    }
  };
  const handleDiamondType = (type) => {
    setDiamondTypeSort(type);
  };
  const handleTypeClick = async (type) => {
    setNoUiText("")
    setType(type);
  };
  const addProject = async () => {
    try {
      startLoading();
      const response = await api.verifyAddProject();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        navigate(paths.ADD_PROJECT);
        stopLoading();
      } else if(apiResponse.status === 5){
        setShowPlansPopup(true)
      }
      else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      stopLoading();
    }
  
  };

  useEffect(() => {
    logMessage(liveTimer);
    if (liveTimer !== 0) {
      const intervalId = setInterval(() => {
        setLiveTimer(liveTimer + 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [liveTimer]);
  const handleTimer = async (type, project_id) => {
    if (type == "stop") {
      stop_timer(project_id);
    } else {
      start_timer(project_id);
    }
    logMessage("handle timer clicked");
  };
  const start_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.startTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        setNoUiText("")
        await fetchProjects(); 
        await stopLoading();
        setShowUI(true);
        if(type == "completed"){
          setNoUiText("Currently, you don't have any completed projects.")
        }else if(type == "ongoing"){
          setNoUiText("Currently, you don't have any projects in progress.")
        }else{
          setNoUiText("Currently, you don't have any projects started.")
        }
     
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      await stopLoading();
      setShowUI(true);
      if(type == "completed"){
        setNoUiText("Currently, you don't have any completed projects.")
      }else if(type == "ongoing"){
        setNoUiText("Currently, you don't have any projects in progress.")
      }else{
        setNoUiText("Currently, you don't have any projects started.")
      }
    }
  };
  const stop_timer = async (project_id) => {
    try {
      startLoading();
      const data = {
        project_id: project_id,
      };
      const response = await api.stopTimer(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        setNoUiText("")
        await fetchProjects();
        await stopLoading();
        setShowUI(true);
        if(type == "completed"){
          setNoUiText("Currently, you don't have any completed projects.")
        }else if(type == "ongoing"){
          setNoUiText("Currently, you don't have any projects in progress.")
        }else{
          setNoUiText("Currently, you don't have any projects started.")
        }
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      await stopLoading();
      setShowUI(true);
      if(type == "completed"){
        setNoUiText("Currently, you don't have any completed projects.")
      }else if(type == "ongoing"){
        setNoUiText("Currently, you don't have any projects in progress.")
      }else{
        setNoUiText("Currently, you don't have any projects started.")
      }
    }
  };

  const openProjectDetail = (project_id) => {
    navigate(paths.PROJECT_DETAIL + "?project_id=" + project_id);
  };
  const handleChange = (e) => {
    setSearchKey(e.target.value);
  };
  const searchProducts = async () => {
    navigate(paths.SEARCH);
    // if (search !== "") {
    //   navigate(paths.SEARCH + "?search=" + search);
    // }
  };

  // useEffect(() => {
  //   const hasFilters = Object.values(selectedFilters).some(
  //     (value) => value !== ""
  //   );
  //   if (hasFilters) {
  //     startLoading();
  //     const timer = setTimeout(async () => {
  //       await fetchProjects();
  //       logMessage("runs second time");
  //       stopLoading();
  //     }, 1000); // 1000 milliseconds delay

  //     return () => clearTimeout(timer);
  //   }
  // }, [selectedFilters]);

  const updateProjectStatus = async () => {
    try {
      startLoading();
      const data = {
        project_id: updateProjectId,
      };
      const response = await api.updateProjectStatus(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        togglePopup();
        fetchProjects();
       await stopLoading();
        setShowUI(true);
        if(type == "completed"){
          setNoUiText("Currently, you don't have any completed projects.")
        }else if(type == "ongoing"){
          setNoUiText("Currently, you don't have any projects in progress.")
        }else{
          setNoUiText("Currently, you don't have any projects started.")
        }
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      await stopLoading();
      setShowUI(true);
      if(type == "completed"){
        setNoUiText("Currently, you don't have any completed projects.")
      }else if(type == "ongoing"){
        setNoUiText("Currently, you don't have any projects in progress.")
      }else{
        setNoUiText("Currently, you don't have any projects started.")
      }
    }
  };
  const handleUpdateStatus = async (id, status) => {
    if (status == "ongoing") {
      logMessage(id);
      logMessage("handle update status clciked");
      setUpdatedId(id);
      togglePopup();
    }
  };
  const navigateToPlans = async () => {
    navigate(`${paths.MY_PROFILE}?viewplan=true`);
  };
  const fetchProjectsNew = async (updatedFilters) => {
    setNoUiText("");
    setProjectData(new DataModel({}));
    try {
      startLoading();
      const data = {
        type: type,
        page: page,
        limit: 100000,
        sort: updatedFilters.sort,
        sortType: updatedFilters.type,
        diamondType: updatedFilters.diamondType,
      };
      const response = await api.getProjectList(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        // logMessage(response.data);
        const projectData = new DataModel(response.data);
        const updatedProjects = projectData.projects.map((project) => {
          if (project.timer_status === "started") {
            setLiveTimer(project.totalDuration);
          }
        });
        setProjectData(projectData);
        await stopLoading();
        setShowUI(true);
        if(type == "completed"){
          setNoUiText("Currently, you don't have any completed projects.")
        }else if(type == "ongoing"){
          setNoUiText("Currently, you don't have any projects in progress.")
        }else{
          setNoUiText("Currently, you don't have any projects started.")
        }
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      await stopLoading();
      setShowUI(true);
      if(type == "completed"){
        setNoUiText("Currently, you don't have any completed projects.")
      }else if(type == "ongoing"){
        setNoUiText("Currently, you don't have any projects in progress.")
      }else{
        setNoUiText("Currently, you don't have any projects started.")
      }
    }
  };
  const fetchProjects = async () => {
    setNoUiText("")
    setProjectData(new DataModel({}));
    try {
      startLoading();
      const data = {
        type: type,
        page: page,
        limit: 100000,
        sort: selectedFilters.sort,
        sortType: selectedFilters.type,
        diamondType: selectedFilters.diamondType,
      };
      const response = await api.getProjectList(data);
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        // logMessage(response.data);
        const projectData = new DataModel(response.data);
        const updatedProjects = projectData.projects.map((project) => {
          if (project.timer_status === "started") {
            setLiveTimer(project.totalDuration);
          }
        });
        setProjectData(projectData);
        await stopLoading();
        setShowUI(true);
        if(type == "completed"){
          setNoUiText("Currently, you don't have any completed projects.")
        }else if(type == "ongoing"){
          setNoUiText("Currently, you don't have any projects in progress.")
        }else{
          setNoUiText("Currently, you don't have any projects started.")
        }
      } else {
        logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      await stopLoading();
      setShowUI(true);
      if(type == "completed"){
        setNoUiText("Currently, you don't have any completed projects.")
      }else if(type == "ongoing"){
        setNoUiText("Currently, you don't have any projects in progress.")
      }else{
        setNoUiText("Currently, you don't have any projects started.")
      }
    }
  };
  const togglePopup = () => {
    logMessage("toggleclicked");
    setShowPopup(!showPopup);
    logMessage(showPopup);
  };
  const toggleSortPopup = () => {
    logMessage("togglesortclicked");
    setSortShowPopup(!showSortPopup);
  };
  useEffect(() => {
    if (type !== "") {
      logMessage("runs first time");
      fetchProjects();
    }
  }, [type]);
  return (
    <div className="full_page_inner">
      <Header />
      <div
        className="dashboard_container"
        style={{ backgroundImage: `url(${imagePaths.dashboard_background})` }}
      >
        <div className="home_container">
        {showPlansPopup && (
            <div className="popup-overlay">
              <div className="popup planspopup">
                <div
                 style={{ borderRadius: "8px", backgroundImage: `url(${imagePaths.viewplans})`, justifyContent: "center", display:"flex", alignItems:"center" }}
                >
                 
                   <img
                    style={{ width:"32px", height:"32px", marginRight:"8px" }}
                        className="premium_icon_inside"
                        src={imagePaths.premium}
                      />
                       
                  <p>Subscribe to premium</p>
                  <p style={{ paddingTop:"5px" , marginLeft:"15px", cursor:"pointer" }} ><MdOutlineCancel style={{width:"20px", height:"20px"}} onClick={() => setShowPlansPopup(false)} />
                  </p>
                </div>
                <div className="redBackPlan">
                  <p>
                  Unable to add more than 5 project?
                  </p>
                  </div>
                  <p
                    style={{margin:0, fontSize:"14px"}}
                  >
                  Subscribe to premium and add unlimited projects.
                  </p>     
               
                <button className="delete-button viewplans-button" onClick={navigateToPlans}>
                Upgrade to Premium
                </button>
                {/* <button
                  className="cancel-button canceldelacc"
                  onClick={togglePopup}
                >
                  Cancel
                </button> */}
              </div>
            </div>
          )}
          {showSortPopup && (
            <div className="popup-overlay">
              <div className="popup newsortpop">
                <div className="popup_header">
                  <h2 className="sortbyTitle">Sort By</h2>
                </div>
                <div className="popbodycontent popbodycontentsort">
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Date Modified</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("desc", "updated_time")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "updated_time"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        Newest
                      </p>
                      <p
                        onClick={() => handleSortButtons("asc", "updated_time")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "updated_time"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        Oldest
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Design</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "design")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "design"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "design")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "design"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Artist</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "artist")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "artist"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "artist")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "artist"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row">
                    <p className="sortTitle">Brand</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleSortButtons("asc", "brand")}
                        className={`sortbuttons asc ${
                          sortPattern === "asc" && sortType === "brand"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortAscending />
                      </p>
                      <p
                        onClick={() => handleSortButtons("desc", "brand")}
                        className={`sortbuttons desc ${
                          sortPattern === "desc" && sortType === "brand"
                            ? "sortSelected"
                            : ""
                        }`}
                      >
                        <AiOutlineSortDescending />
                      </p>
                    </div>
                  </div>
                  <div className="popup_row sort_pop_row lastrow">
                    <p className="sortTitle">Diamond Type</p>
                    <div className="rightsortgroup">
                      <p
                        onClick={() => handleDiamondType("square")}
                        className={`sortbuttons asc ${
                          diamondTypeSort === "square" ? "sortSelected" : ""
                        }`}
                      >
                        Square
                      </p>
                      <p
                        onClick={() => handleDiamondType("round")}
                        className={`sortbuttons desc ${
                          diamondTypeSort === "round" ? "sortSelected" : ""
                        }`}
                      >
                        Round
                      </p>
                    </div>
                  </div>
                  <button className="delete-button" onClick={handleSort}>
                    Sort
                  </button>
                  {/* <button className="cancel-button" onClick={togglePopup}>
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          )}
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup">
                {/* <h2>Do you want to upda</h2> */}
                <p>Are you sure you want to set this project as completed?</p>
                {/* <div className="warning">
                  <strong>
                    <IoIosWarning /> Warning
                  </strong>
                  <p>
                    By deleting the account you will lose all your projects data
                    and media.
                  </p>
                </div> */}
                <button className="delete-button" onClick={updateProjectStatus}>
                  Yes
                </button>
                <button className="cancel-button" onClick={togglePopup}>
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div className="outer_banner">
            <div className="mid_banner" style={{ position: "initial" }}>
              <div className="left_mid_container">
                <h4>Projects</h4>
              </div>
              <div className="right_mid_container">
                <div className="inner_right_group">
                  <div className="search-container">
                    <PiMagnifyingGlassLight
                      className="searchIcon"
                      onClick={() => searchProducts()}
                    />
                    <input
                      onClick={() => searchProducts()}
                      type="text"
                      readOnly
                      value={search}
                      className="searchBox"
                      onChange={handleChange}
                      placeholder="Search"
                    ></input>
                  </div>
                  {/* <input type='text' className='searchBox' placeholder='Search'></input> */}
                  <span className="sortHome" onClick={toggleSortPopup}>
                    Sort by <BiSort />
                  </span>
                  <span onClick={addProject}>New Project +</span>
                </div>
              </div>
            </div>
            <div className="filters">
              {selectedFilters.sort && (
                <div className="sortType">
                  {selectedFilters.type === "asc" && <AiOutlineSortAscending />}
                  {selectedFilters.type === "desc" && (
                    <AiOutlineSortDescending />
                  )}
                  <>
                    {sortTypeKey}
                    <AiOutlineClose
                      onClick={() => clearFilter("sort")}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                  </>
                </div>
              )}
              {selectedFilters.diamondType && (
                <div className="diamondType sortType">
                  {selectedFilters.diamondType}{" "}
                  <AiOutlineClose
                    onClick={() => clearFilter("diamondType")}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="inner_container">
            <div className="table_container" style={{ marginTop: "5px" }}>
              <div className="table_inner_card">
                <div className="button_group_dashboard ">
                  <span
                    className={type === "completed" ? "activespan" : ""}
                    onClick={() => handleTypeClick("completed")}
                  >
                    Completed
                  </span>
                  <span
                    className={type === "ongoing" ? "activespan" : ""}
                    onClick={() => handleTypeClick("ongoing")}
                  >
                    In-Progress
                  </span>
                  <span
                    className={type === "not_started" ? "activespan" : ""}
                    onClick={() => handleTypeClick("not_started")}
                  >
                    Not Started
                  </span>
                </div>
                {projectData.projects.length > 0 ? (
                  <table className="projectsTable">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Design</th>
                        <th>Artist</th>
                        <th>Brand</th>
                        <th>Logged time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectData.projects.length > 0 ? (
                        projectData.projects.map((project) => (
                          <tr
                            key={project._id}
                            onClick={() => openProjectDetail(project._id)}
                          >
                            <td>
                              <img
                                alt="project_image"
                                className="table_image"
                                src={project.image[0]}
                              ></img>
                            </td>
                            <td className="design_text">{project.design}</td>
                            <td>© {project.artist}</td>
                            <td>{project.brand}</td>
                            <td className='timesection'>
                              {" "}
                              {project && (
                                <>
                                  {project.timer_status === "started" ? (
                                    <>
                                      <FaRegCirclePause
                                        className="timer_duration"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleTimer("stop", project._id);
                                        }}
                                      />
                                      {convertSecondsToHoursLive(liveTimer)}
                                    </>
                                  ) : (
                                    <>
                                      <FaRegCirclePlay
                                        className="timer_duration"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleTimer("start", project._id);
                                        }}
                                      />
                                      {convertSecondsToHours(
                                        project.totalDuration
                                      )}
                                    </>
                                  )}
                                </>
                              )}{" "}
                            </td>
                            <td>
                              <span
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleUpdateStatus(
                                    project._id,
                                    project.project_status
                                  );
                                }}
                                className={project.project_status}
                              >
                                {" "}
                                <LuRadar />{" "}
                                {convertText(project.project_status)}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="no_project">
                          {showUI && (
                            <div>
                             <p>
                             Currently, you don't have any projects in progress.
                              </p> 
                         </div>
                          )}
                        </div>
                      )}
                    </tbody>
                  </table>
                ) : (
                  // {showUI ? &&(
                  <div className="no_project">
                  {showUI && (
                            <div>
                             <p>
                             {noUItext}
                              </p> 
                         </div>
                          )}
                  </div>
                  // )}
                  // </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectScreen;
