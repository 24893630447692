//Header.js
import './common.css';
import React, { useEffect, useState } from 'react';
import imagePaths from '../../assets/imagePaths';
import { FaHome, FaAngleDown, FaRegUserCircle } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaShuffle } from "react-icons/fa6";
import { MdOutlineLeaderboard } from "react-icons/md";
import { GiStack } from "react-icons/gi";
import { CiShop } from "react-icons/ci";
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkAuthorizationToken, getLocalUserDataByKey } from '../../utils/helpers.js';
import { logMessage } from '../../utils/helpers';
import { signOutUser } from '../../utils/helpers.js';
import paths from '../../utils/constants.js';
import api from '../../api/api.js';
import { ApiResponse } from '../../models/ApiResponseModel.js';

const Header = () => {
  const navigate = useNavigate();
  const [showSortPopup, setSortShowPopup] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const [monthlyTitle, setTitleMonthly] = useState("");
  const [annualTitle, setTitleAnnual] = useState("");
  const [priceMonth, setPriceMonthly] = useState("");
  const [priceTextMonthly, setPriceTextMonthly] = useState("");
  const [priceAnnual, setPriceAnnual] = useState("");
  const [priceTextAnnual, setPriceTextAnnual] = useState("");
  const [priceDescriptionMonth, setDescriptionMonth] = useState("");
  const [priceDescriptionAnnual, setDescriptionAnnual] = useState("");
  const [subscribeMonthLink, setMonthSubscribeLink] = useState("");
  const [subscribeAnnualLink, setAnnualSubscribeLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(imagePaths.demo_profile);
  const name = localStorage.getItem("user_name");
  const location = useLocation();

  const toggleDropdown = () => {
    logMessage("clicked");
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    logMessage("outsideclicked");
    signOutUser(navigate)
  };

  const gotoMyProfile = () => {
    logMessage("outsideclicked");
    navigate(paths.MY_PROFILE);
  };
  const gotoSupport = () => {
    // navigate(paths.MY_PROFILE + "?action=support");
    window.location.href = `${paths.MY_PROFILE}?action=support`;
  };
  const openSubscribeBox = () => {
    if (showSortPopup == false) {
      getSubscriptionPlan();
    }
    setSortShowPopup(!showSortPopup);
  };

  const getSubscriptionPlan = async () => {
    try {
      // startLoading();
      const response = await api.getSubscriptionPlans();
      const apiResponse = new ApiResponse(response.status, response.message);
      if (apiResponse.status === 1) {
        let benefitsData = response.benefits;
        setBenefits(benefitsData);
        let subData = response.data;

        subData.forEach(plan => {
          if (plan.frequencyInterval === "MONTH") {
            setTitleMonthly(plan.title);
            setPriceMonthly(plan.price);
            setDescriptionMonth(plan.frequencyName);
            setMonthSubscribeLink(plan.cartLink);
            setPriceTextMonthly(plan.priceText)
          } else if (plan.frequencyInterval === "YEAR") {
            setTitleAnnual(plan.title);
            setPriceAnnual(plan.price);
            setDescriptionAnnual(plan.frequencyName);
            setAnnualSubscribeLink(plan.cartLink);
            setPriceTextAnnual(plan.priceText)
          }
        });
      } else {
        // logMessage(apiResponse.message, "alert");
      }
    } catch (error) {
      logMessage(error);
    } finally {
      // stopLoading();
    }
  }
  const handleMonthlySubscription = () => {
    setSortShowPopup(!showSortPopup);
    const width = Math.floor(window.screen.width * 0.99);
    const height = 800;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const newWindow = window.open(subscribeMonthLink, 'popup',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (newWindow) {
      const timer = setInterval(() => {

        if (newWindow.closed) {
          clearInterval(timer);
          // window.location.reload();
          window.location.href = paths.MY_PROFILE;
          // navigate(paths.MY_PROFILE);
        }
      }, 1000);
    } else {
      // alert('Please allow popups for this website');
      logMessage("Please allow popups for this website", "alert");

    }
  }
  const handleAnnualSubscription = () => {
    setSortShowPopup(!showSortPopup);
    const width = 800;
    const height = 800;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const newWindow = window.open(subscribeAnnualLink, 'popup',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (newWindow) {
      const timer = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(timer);
          // window.location.reload();
          window.location.href = paths.MY_PROFILE;
          // navigate(paths.MY_PROFILE);
        }
      }, 1000);
    } else {
      // alert('Please allow popups for this website');
      logMessage("Please allow popups for this website", "alert");

    }
  }

  const handleClickOutside = (event) => {
    logMessage("outsideclicked");
    if (event.target.classList.contains('menuInner') || event.target.classList.contains('dropbtn')) {
    } else {
      logMessage("inside menu outside");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || token.length <= 0) {
      navigate(paths.AUTH_SCREEN);
    }else{
      if (window.location.pathname !== paths.MY_PROFILE) {
      const isVerified = localStorage.getItem('isVerified');
      if (isVerified !== 'true') {
          // window.location.href = paths.MY_PROFILE;
          navigate(paths.MY_PROFILE);
      }
    }
  }
  }, [navigate]);


  // useEffect(() => {
  //   console.log("calledgain")
  //   getSubscriptionPlan();
  // }, []);

  useEffect(() => {
    if (getLocalUserDataByKey("profile_picture") !== "") {
      setProfilePicture(getLocalUserDataByKey("profile_picture"));
    }
    checkAuthorizationToken(navigate)
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return (
    <header>
      <div className="header-left">
        <img src={imagePaths.logohorizontal} alt="Logo" />
      </div>
      <div className="header-right header-center">
        <nav>
          <ul>
            <li><NavLink exact to={paths.HOME_SCREEN}><FaHome /> Home</NavLink></li>
            <li><NavLink exact to={paths.PROJECT_SCREEN}><GiStack /> Projects</NavLink></li>
            <li><NavLink exact to={paths.LEADERBOARD}><MdOutlineLeaderboard />Leaderboard</NavLink></li>
            <li><NavLink exact to={paths.SHUFFLE}><FaShuffle />Shuffle</NavLink></li>
            {/* <li><a href="#"><FaShuffle />Shuffle</a></li> */}
            <li><NavLink exact to={paths.SHOP}><CiShop /> Shop</NavLink></li>

          </ul>
        </nav>
        <div className="vertical-line"></div>
        <span className="openSubHeader" id='showMainSubBox' style={{ display: 'none' }} onClick={openSubscribeBox} >Subscribe</span>
        <img src={profilePicture} alt="profilepicture" className="header_profile"></img>
        <span className='headerProfile'>{name}</span>
        <FaAngleDown className="dropbtn" onClick={toggleDropdown} />
        {isOpen && (
          <div className="dropdown-content header-dropdown">
            <p className="menuInner" onClick={gotoMyProfile} ><FaRegUserCircle />  My Profile</p>
            {/* <p className="menuInner" onClick={goToAboutUs} ><FiInfo />  About</p> */}
            <p className="menuInner" onClick={gotoSupport}><BiSupport />  Support</p>
            <p className="menuInner" onClick={handleSignOut}><RiLogoutCircleRLine />  Log Out</p>
          </div>
        )}
      </div>
      {showSortPopup && benefits.length > 0 && (
        <div className="popup-overlay">
          <div className="popup newsortpop">
            <div className="premium_header">
              <h2 onClick={openSubscribeBox} className="premiumTitle">
                Subscribe to premium
              </h2>
              <p>
                <MdOutlineCancel onClick={openSubscribeBox} />
              </p>
            </div>
            <div
              className="popbodycontent premiumFullBox"
              style={{ backgroundImage: `url(${imagePaths.auth_background})` }}
            >
              <div className="topBox">
                <img className="premium_icon" src={imagePaths.premium} />
              
                <div className="benifitsBox">
                  <p className="benefitTitle">Unlock All Features</p>
                  <ul className="benefitsList">
                    {benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>  // Dynamically render each benefit as a <li>
                    ))}
                  </ul>
                </div>
               
                <h2 className="premiumTitleInner">Subscription Plans</h2>
                <div className="subscriptionBoxes">
                  <div className="monthlySubscription subBox">
                    <div className="topSubBox">
                      <img
                        className="premium_icon_inside"
                        src={imagePaths.premium}
                      />
                      <p className="subTitle">{monthlyTitle}</p>
                    </div>
                    <div className="plan_inner">
                      <p>
                        <b>{priceMonth}</b> {priceTextMonthly}
                      </p>
                      <p className='descriptionSub'>{priceDescriptionMonth}</p>
                      <button
                        onClick={handleMonthlySubscription}
                        className="subscribeMonthly">
                        Try for free
                      </button>
                    </div>
                  </div>
                  <div className="annualSubscription subBox">
                    <div className="topSubBox">
                      <img
                        className="premium_icon_inside"
                        src={imagePaths.premium}
                      />
                      <p className="subTitle"> {annualTitle}</p>
                    </div>
                    <div className="plan_inner">
                      <p>
                        <b>{priceAnnual}</b> {priceTextAnnual}
                      </p>
                      <p className='descriptionSub'>{priceDescriptionAnnual}</p>
                      <button
                        onClick={handleAnnualSubscription}
                        className="subscribeAnnualy"
                      >
                        Try for free
                      </button>
                    </div>
                  </div>
                </div>
              </div>
               <div className="policyBox">
                  <button onClick={() => window.open(paths.TERMS, "_blank")}>Terms of Use</button>
                  <button onClick={() => window.open(paths.PRIVACY, "_blank")}>Privacy Policy</button>
                </div>
              
                <div className="renmessagebox">
                  <IoMdInformationCircle />
                  <p className="autoRenMessage">
                    All Subscriptions are auto renewing.
                  </p>
                </div>
              {/* <button className="cancel-button" onClick={togglePopup}>
                    Cancel
                  </button> */}
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
