// api.js

import axios from "axios";
import {
  BASE_URL,
  SIGNUP,
  API_URL,
  LOGIN,
  SEND_OTP,
  VERIFY_OTP,
  CHANGE_FORGOT_PASSWORD,
  GET_PROJECTS_LIST,
  SEND_CANCEL_SUBS_OTP,
  VERIFY_CANCEL_SUBS_OTP,
  GET_PROJECTS_DETAIL,
  SEARCH_PROJECT,
  START_TIMER,
  STOP_TIMER,
  GET_HOME_DATA,
  ADD_PROJECT,
  EDIT_PROFILE,
  GET_PROFILE,
  CHANGE_PASSWORD,
  SCAN_PRODUCT,
  DELETEACCOUNT,
  DELETEPROJECT,
  EDITPROJECT,
  UPDATEPROJECTSTATUS,
  EDITTIMER,
  GETSUBDETAIL,
  CANCELSUB,
  VERIFYADD,
  GETSUBSCRIPTIONPLAN,
  ADD_CANVAS_IMAGES,
  GET_LEADERBOARD,
  SEND_SUPPORT_EMAIL,
} from "./apiConstants"; // Import API constants
import { logMessage } from "../utils/helpers";
import { uploadImage } from "../services/firebase";

// var authorizationToken = localStorage.getItem("token");
// logMessage(authorizationToken);
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 100000,
  headers: {
    api_key: API_URL,
  },
});

const axiosInstanceAfterLogin = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    api_key: API_URL,
    authorization: localStorage.getItem("token"),
  },
});

const api = {
  signUp: async (userData) => {
    try {
      const response = await axiosInstance.post(SIGNUP, userData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  SendCancelSubsOtp: async () => {
    try {
      axiosInstanceAfterLogin.defaults.headers.authorization =
      localStorage.getItem("token");
      const response = await axiosInstanceAfterLogin.get(SEND_CANCEL_SUBS_OTP);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  VerifyCancelSubsOtp: async (data) => {
    try {
      axiosInstanceAfterLogin.defaults.headers.authorization =
      localStorage.getItem("token");
      const response = await axiosInstanceAfterLogin.post(VERIFY_CANCEL_SUBS_OTP, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  login: async (userData) => {
    try {
      const response = await axiosInstance.post(LOGIN, userData);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  sendOtp: async (data) => {
    try {
      const response = await axiosInstance.post(SEND_OTP, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  verifyOtp: async (data) => {
    try {
      const response = await axiosInstance.post(VERIFY_OTP, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  changeForgotPassword: async (data) => {
    try {
      const response = await axiosInstance.post(CHANGE_FORGOT_PASSWORD, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  getProjectList: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(
        GET_PROJECTS_LIST,
        data
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  getProjectDetail: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(
        GET_PROJECTS_DETAIL,
        data
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  searchProduct: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(SEARCH_PROJECT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error; // Rethrow error to handle in components
    }
  },
  startTimer: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(START_TIMER, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  stopTimer: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(STOP_TIMER, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getHomeScreenData: async () => {
    try {
        axiosInstanceAfterLogin.defaults.headers.authorization =
        localStorage.getItem("token");
      const response = await axiosInstanceAfterLogin.get(GET_HOME_DATA);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  addProject: async (data) => {
    try {
      const uploadedImageUrls = [];
      for (const image of data.image) {
        if (image instanceof File) {
          const uploadedImageUrl = await uploadImage(image);
          uploadedImageUrls.push(uploadedImageUrl);
        } else {
          uploadedImageUrls.push(image);
        }
      }
      const dataToSubmit = {
        ...data,
        tags: JSON.stringify(data.tags),
        image: JSON.stringify(uploadedImageUrls),
      };
      const response = await axiosInstanceAfterLogin.post(
        ADD_PROJECT,
        dataToSubmit
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  editProfile: async (data, imageChange) => {
    try {
      let uploadedImageUrl = data.image;
      if (imageChange) {
        const uploadedUrl = await uploadImage(data.image);
        uploadedImageUrl = uploadedUrl;
      }
      const dataToSubmit = { ...data, image: uploadedImageUrl };
      // logMessage(dataToSubmit);
      const response = await axiosInstanceAfterLogin.post(
        EDIT_PROFILE,
        dataToSubmit
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getProfile: async () => {
    try {
      const response = await axiosInstanceAfterLogin.get(GET_PROFILE);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  changePassword: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(
        CHANGE_PASSWORD,
        data
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  scanProduct: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(SCAN_PRODUCT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  deleteAccount: async () => {
    try {
      const response = await axiosInstanceAfterLogin.get(DELETEACCOUNT);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  deleteProjectData: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(DELETEPROJECT, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  editTimer: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(EDITTIMER, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  editProject: async (data) => {
    try {
      const uploadedImageUrls = [];
      for (const image of data.image) {
        if (image instanceof File) {
          const uploadedImageUrl = await uploadImage(image);
          uploadedImageUrls.push(uploadedImageUrl);
        } else {
          uploadedImageUrls.push(image);
        }
      }
      // logMessage(uploadedImageUrls);
      // logMessage("datapush");
      const dataToSubmit = {
        ...data,
        tags: JSON.stringify(data.tags),
        image: JSON.stringify(uploadedImageUrls),
      };
      // logMessage(dataToSubmit);
      const response = await axiosInstanceAfterLogin.post(
        EDITPROJECT,
        dataToSubmit
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  updateProjectStatus: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(UPDATEPROJECTSTATUS, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getSubscriptionDetail: async () => {
    try {
      const response = await axiosInstanceAfterLogin.get(GETSUBDETAIL);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  cancelSubscription: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(CANCELSUB, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getLeaderboard: async (data) => {
    try {
      logMessage(data);
      const response = await axiosInstanceAfterLogin.post(GET_LEADERBOARD, data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  verifyAddProject: async () => {
    try {
      const response = await axiosInstanceAfterLogin.get(VERIFYADD);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  sendSupportEmail: async (data) => {
    try {
      const response = await axiosInstanceAfterLogin.post(SEND_SUPPORT_EMAIL,data);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getSubscriptionPlans: async () => {
    try {
      const response = await axiosInstance.get(GETSUBSCRIPTIONPLAN);
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  getProductsDetail: async () => {
    try {

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://shopdreamerdesigns.myshopify.com/admin/api/2024-01/products/7356546973762.json',
        headers: { 
          'X-Shopify-Access-Token': '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', 
          'api_key': 'Och8289v5Aty5S2oUG9PUKhSy2geZh9r3Wum(lhfAREdwEZ*%ZOeyAOa7XsWuOxGWs8KeWHgF*UwEwRjjZzIFaWTVs8R0s5m%9'
        }
      };
      
      axios.request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // console.log(error);
      });
      
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
  addCanvasImages: async (projectId, data, type) => {
    try {
      let uploadImageUrl="";

        if (data.image instanceof File) {
          uploadImageUrl = await uploadImage(data.image); 
        }
      
      let dataToSubmit = {
      };
      if(type === "start"){
         dataToSubmit = {
          project_id: projectId,
          canvas_start_image: uploadImageUrl,
        };
      }else{
         dataToSubmit = {
          project_id: projectId,
          canvas_complete_image: uploadImageUrl,
        };
      }
      logMessage(dataToSubmit)
      const response = await axiosInstanceAfterLogin.post(
        ADD_CANVAS_IMAGES,
        dataToSubmit
      );
      if (response.status !== 200) {
        const errorMessage = `Error: ${response.statusText} (${response.status})`;
        // alert(errorMessage);
        throw new Error(errorMessage);
      }
      return response.data;
    } catch (error) {
      // alert(error);
      console.error("Error fetching users:", error);
      throw error;
    }
  },
};

export default api;
